

















































































































import { Vue, Component } from 'vue-property-decorator';
import {
  initMap,
  zoomUp,
  zoomDown,
  overlayCenter,
  drawMall,
  clearMall,
  processPolygonData,
  drawPolygon,
  clearOverlay,
  drawMarkers,
  regionPolygonStyle,
  drawLabel,
  drawMarker,
  drawWingManager,
  clearDrawWingManager,
  isPolygonsOverlap,
  getBoundaryAry,
  computeArea,
  getGeocoder,
  processPath,
  isXss,
  getPolygonAreaCenter,
} from '@/components/map/map';
import {
  getAOILookOverInfo,
  getMallByUserId,
  getGridBussinessCircle,
  getAoiDataByMallId,
  addAoi,
} from '@/services/sourceApi';
import { initDate } from '@/components';
import { Grid } from '@/components/map/grid';
import regionLegend from '@/components/RegionLegend/index.vue';
import { LabelStyle } from '@/types/store';
import { geocoder } from '@/services/bigdata';
@Component({
  components: {
    regionLegend,
  },
})
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable operator-linebreak */
export default class AddRegion extends Vue {
  map!: any;
  mallStatus = false;
  searchRegionVal = '';
  mallSelectModal = '';
  title = '申请新区域';
  transfer = false;
  cityList: any = [];
  gridData: any = [];
  dateValue = initDate('d');
  $dayjs: any;
  // gridColor = ['rgba(255,255,255,.1)'];
  gridColor = ['rgba(255,255,222,0.3)'];
  grid!: any;
  color = this.gridColor[0];
  gridColorObj = {
    color: this.gridColor,
    top0Color: this.color,
    top1Color: this.color,
    strokeStyle: 'rgba(80,128,215,1)',
    lineWidth: 0,
    textColor: 'black',
  };
  mallMarker!: any;
  regionMarker!: any;
  mallPolygon!: any;
  mallId!: number;
  confirmStatus = true;
  beginText = '开始勾画';
  endText = '重新勾画';
  sketchText = this.beginText;
  // geocoder: any;
  zoom = 16;
  regionCity = '';
  labelStyle: LabelStyle = {
    color: '#4583ff',
    border: 'none',
    backgroundColor: 'none',
    pointerEvents: 'none',
    transform: 'translateY(-45%) translateX(-50%)',
    zIndex: 10,
  };
  overlayAry: any = [];
  aoiMarkerAry: any = [];
  drawingManager: any;
  polygonPath: any;
  drawingManagerPolygon: any;
  aoiBoundaryAry: any = [];
  mallBoundaryAry: any = [];
  polygonCompleteRes: any;
  addAoiModal = false;
  aoiName = '';
  radioModal = '1';
  aoiInfo: any = {};
  adcode = 0;
  $turf: any;
  city = '';
  clearTime: any;
  dateOptions = {
    disabledDate: (date: Date): number => {
      const dates = this.$dayjs(date).format('YYYYMMDD');
      const maxDate = this.$dayjs(initDate('d')).format('YYYYMMDD');
      let isChecked = 0;
      if (dates > maxDate) {
        isChecked = 1;
      }
      return isChecked;
    },
  };
  async mounted(): Promise<void> {
    const { zoom } = this;
    const el = 'map-container1';
    const center = '39.916527,116.397128';
    this.map = initMap(el, zoom, center);
    this.mapZoomEvent();
    this.getMallByUserId();
  }

  pageClickHandler(): void {
    this.getMallByUserId();
  }

  zoomUp(): void {
    zoomUp(this.map);
  }

  zoomDown(): void {
    zoomDown(this.map);
  }

  async getMallByUserId(): Promise<any> {
    const res: any = await getMallByUserId();
    if (res.status === 0) {
      const data: any = Object.values(res.data);
      data.forEach((item: any) => {
        const {
          mall_id: mallId,
          name,
          boundary_gcj: boundary,
          center_gcj: center,
          adcode,
          city,
        } = item;
        const obj = {
          value: `${mallId}-${center}-${boundary}-${adcode}-${city}`,
          label: name,
        };
        this.cityList.push(obj);
      });
      const {
        mall_id: mallId,
        center_gcj: center,
        boundary_gcj: boundary,
        adcode,
        city,
      } = data[0];
      this.mallSelectModal = `${mallId}-${center}-${boundary}-${adcode}-${city}`;
      this.mallId = mallId;
      this.gridData = await this.gridDataHandler();
      this.mallChangeHandler2(center, boundary);
      const aoiData = await this.aoiDataHandler();
      this.aoiRegionHandler(aoiData);
      this.adcode = Number(adcode);
      this.city = city;
    }
  }

  aoiRegionHandler(aoiData: any): void {
    if (this.overlayAry.length !== 0) {
      this.overlayAry.forEach((item: any) => {
        item.setMap(null);
      });
      this.overlayAry = [];
      this.aoiMarkerAry = [];
    }
    this.aoiBoundaryAry = [];
    aoiData.forEach((item: any) => {
      const {
        aoi_id: aoiId,
        aoi_name: aoiName,
        center_gcj: centerGcj,
        boundary,
        status: statusStr,
      } = item;
      const status = Number(statusStr);
      if (status !== -1 && status !== 0) {
        this.aoiFillDom(centerGcj, boundary, aoiId, aoiName, status);
      }
    });
  }

  aoiFillDom(
    centerGcj: string,
    boundary: string,
    aoiId: number,
    aoiName: string,
    status: number,
  ) {
    const center = overlayCenter(centerGcj);
    const path = processPolygonData(boundary);
    // 边界覆盖物
    const polygon = drawPolygon(this.map, path);
    regionPolygonStyle(String(status), polygon);
    // 名称覆盖物
    const nameContent = `<div style="width: 100%;height:100%;text-align:center;font-size: 14px;margin-top:54px;">${aoiName}</div>`;
    const nameLabel = drawLabel(
      this.map,
      center,
      nameContent,
      0,
      aoiId,
      this.labelStyle,
      true,
    );
    const aoiMarker = drawMarker(this.map, center);
    this.overlayAry.push(polygon, nameLabel, aoiMarker);
    this.aoiMarkerAry.push(nameLabel);
    const boundaryAry = getBoundaryAry(boundary);
    this.aoiBoundaryAry.push(boundaryAry);
  }

  radioChangeHandler(val: string): void {
    this.radioModal = val;
  }

  mapZoomEvent(): void {
    (window as any).qq.maps.event.addListener(this.map, 'zoom_changed', () => {
      // console.log(event);
      const zoom = this.map.getZoom();
      if (this.aoiMarkerAry.length > 0) {
        const status = zoom > 15;
        this.aoiMarkerAry.forEach((item: any) => {
          // setVisible
          item.setVisible(status);
        });
      }
    });
  }
  mallChangeHandler2(center: string, boundary: string): void {
    this.mallBoundaryAry = [];
    const centers = overlayCenter(center);
    this.map.setCenter(centers);
    clearMall(this.mallMarker);
    clearOverlay(this.mallPolygon);
    this.mallMarker = drawMall(this.map, centers);
    const path = processPolygonData(boundary);
    this.mallPolygon = drawPolygon(this.map, path);
    const boundaryAry = getBoundaryAry(boundary);
    this.mallBoundaryAry.push(boundaryAry);
  }

  async dateChangeHandler(dates: string): Promise<any> {
    const date = this.$dayjs(dates).format('YYYY-MM-DD');
    this.dateValue = date;
    this.gridData = await this.gridDataHandler();
    if (this.mallStatus) {
      this.drawGrid(this.gridData);
    }
  }

  async aoiDataHandler(): Promise<any> {
    let mall_id = this.mallId;
    if (typeof(this.mallId) === 'string') {
      mall_id = parseInt(this.mallId, 10);
    }
    const params = {
      mall_id,
    };
    const res: any = await getAoiDataByMallId(params);
    return res.data;
  }

  async gridDataHandler(): Promise<any> {
    const params = {
      mall_id: this.mallId,
      date: this.dateValue,
      type: 1,
      property: 1,
      duration: 'd',
      start_percent: 0,
      end_percent: 100,
    };
    const gridRes: any = await getGridBussinessCircle(params);
    return gridRes.data;
  }

  mallSwitchChangeHandler(status: boolean): void {
    this.mallStatus = status;
    if (status === true) {
      this.drawGrid(this.gridData);
    } else {
      this.drawGrid([]);
    }
  }

  sketchRegionClickHandler(): void {
    if (this.sketchText === this.endText) {
      this.confirmStatus = true;
      clearDrawWingManager(this.polygonCompleteRes);
      clearOverlay(this.drawingManagerPolygon);
    }
    this.drawingManager = drawWingManager(this.map, 'POLYGON');
    this.drawWingManagerBindEvent();
  }

  confirmClickHandler(): void {
    this.addAoiModal = true;
    this.addAoiHandler();
  }

  drawWingManagerBindEvent(): void {
    (window as any).qq.maps.event.addListener(
      this.drawingManager,
      'polygoncomplete',
      (res: any) => {
        this.polygonCompleteRes = res;
        const polygonPath = res.getPath().elems;
        this.polygonPath = polygonPath;
        // 限制勾画区域面积 最大10平方公里，最小0.01平方公里。
        const araeStatus = this.restrictedArea(polygonPath);
        if (araeStatus === true) {
          clearDrawWingManager(res);
          clearDrawWingManager(this.drawingManager);
          this.drawingManagerPolygon = drawPolygon(this.map, polygonPath);
          regionPolygonStyle('-1', this.drawingManagerPolygon);
        }
        const boundaryStatus = this.ifBoundaryRepeat(polygonPath, res);
        if (araeStatus && boundaryStatus) {
          return false;
        }
        if (araeStatus || boundaryStatus) {
          return false;
        }
        clearDrawWingManager(this.drawingManager);
        this.sketchText = this.endText;
        // aoi信息获取
        getGeocoder('', polygonPath, this.mallId, (aoiInfo: any) => {
          this.radioModal = '1';
          this.aoiInfo = aoiInfo;
          const obj = getPolygonAreaCenter(polygonPath);
          this.aoiInfo.centre_ll = `${obj.lat},${obj.lng}`;
          if (this.adcode !== this.aoiInfo.adcode) {
            clearDrawWingManager(res);
            clearDrawWingManager(this.drawingManager);
            this.drawingManagerPolygon = drawPolygon(this.map, polygonPath);
            regionPolygonStyle('-1', this.drawingManagerPolygon);
            this.confirmStatus = true;
            const text = `施画区域超出城市范围，请在${this.city}市内施画`;
            this.$Modal.error({
              title: this.title,
              content: text,
              onOk: async () => {
                console.log(text);
              },
            });
            return;
          }
          this.confirmStatus = false;
          this.addAoiModal = true;
        });
      },
    );
  }

  async addAoiClickHandler(): Promise<any> {
    this.aoiInfo.aoi_name = this.aoiName;
    this.aoiInfo.num_type = this.radioModal;
    if (this.aoiName === '') {
      this.$Modal.error({
        title: this.title,
        content: '区域名称不能为空',
        onOk: async () => {
          this.addAoiModal = true;
        },
      });
      return;
    }
    const aoiRes = await this.addAoi(this.aoiInfo);
    if (aoiRes.status === 0 && aoiRes.data) {
      this.$Modal.success({
        title: this.title,
        content: '申请成功',
        onOk: async () => {
          this.radioModal = '1';
          this.aoiName = '';
          this.confirmStatus = true;
          this.sketchText = this.beginText;
          clearDrawWingManager(this.polygonCompleteRes);
          const {
            centre_ll: centerGcj,
            boundary,
            aoi_name: aoiName,
          } = this.aoiInfo;
          this.aoiFillDom(centerGcj, boundary, 0, aoiName, 2);
        },
      });
    } else {
      this.$Modal.error({
        title: this.title,
        content: `申请失败${aoiRes.info}`,
        onOk: async () => {
          // this.radioModal = '1';
          // this.aoiName = '';
          this.addAoiModal = true;
        },
      });
    }
    // this.addAoiHandler();
  }

  async addAoi(data: any): Promise<any> {
    const params = {
      ...data,
    };
    params.mall_id = parseInt(params.mall_id, 10);
    params.num_type = parseInt(params.num_type, 10);
    const addRes: any = await addAoi(params);
    return addRes;
  }

  btKeyUpAoiName(e: any): void {
    if (this.clearTime) clearTimeout(this.clearTime);
    this.clearTime = setTimeout(() => {
      this.aoiName = isXss(e.target.value);
    }, 0);
  }

  addAoiHandler(): void {
    // console.log('adoi');
  }

  ifBoundaryRepeat(currentPath: any, res: any) {
    const pathAry: any = processPath(currentPath);
    // aoi_boundary_status
    // console.log(this.aoiBoundaryAry);
    const aoiBoundaryStatus = this.aoiBoundaryAry.some((item: any) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      isPolygonsOverlap(pathAry, item));
    const mallBoundaryStatus = this.mallBoundaryAry.some((item: any) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      isPolygonsOverlap(pathAry, item));
    // const addCheckPendingStatus = this.addCheckPending.some(item => isPolygonsOverlap(newAppl, item));
    let resStatus = false;
    // 判断重叠
    if (
      aoiBoundaryStatus === true ||
      mallBoundaryStatus === true
      // || addCheckPendingStatus === true
    ) {
      const polygon = drawPolygon(this.map, currentPath);
      regionPolygonStyle('-1', polygon);
      const content = '新勾画区域不能与现有区域发生重叠，请重新勾画';
      this.$Modal.error({
        title: this.title,
        content,
        okText: '知道了',
        onOk: async () => {
          clearDrawWingManager(res);
          clearDrawWingManager(this.drawingManager);
          clearOverlay(polygon);
        },
      });
      resStatus = true;
    } else {
      resStatus = false;
    }
    return resStatus;
  }

  restrictedArea(resPathAry: any): boolean {
    const area = computeArea(resPathAry);
    this.confirmStatus = true;
    let status = false;
    if (area > 10) {
      this.$Modal.error({
        title: this.title,
        content: `当前勾画区域面积${area}平方公里，超出最大面积限制（10平方公里），请重新勾画`,
        okText: '知道了',
        onOk: async () => {
          clearOverlay(this.drawingManagerPolygon);
        },
      });
      status = true;
    } else if (area < 0.01) {
      this.$Modal.error({
        title: this.title,
        content: `当前勾画区域面积${area}平方公里，超出最小面积限制（0.01平方公里），请重新勾画`,
        okText: '知道了',
        onOk: async () => {
          clearOverlay(this.drawingManagerPolygon);
        },
      });
      status = true;
    }
    return status;
  }

  async positionClickHandler() {
    if (this.searchRegionVal === '') {
      return;
    }
    const res: any = await geocoder({
      address: this.searchRegionVal,
    });
    const resLatlng = res.result.location;
    const latLng = new (window as any).qq.maps.LatLng(
      resLatlng.lat,
      resLatlng.lng,
    );
    this.map.setCenter(latLng);
    this.map.setZoom(this.zoom);
    clearOverlay(this.regionMarker);
    this.regionMarker = drawMarkers(this.map, latLng);
    const { city } = res.result.address_components;
    this.regionCity = city;
  }

  drawGrid(data: any): void {
    const { gridColorObj } = this;
    this.grid = new (Grid as any)({
      map: this.map,
      data,
      color: gridColorObj.color,
      weightFiled: 'num',
      weightType: 2,
      rankFiled: 'rank',
      top0Color: gridColorObj.top0Color,
      top1Color: gridColorObj.top1Color,
      strokeStyle: gridColorObj.strokeStyle,
      lineWidth: gridColorObj.lineWidth,
      textColor: gridColorObj.textColor,
    });
  }

  async mallChangeHandler(params: any): Promise<void> {
    const { value } = params;
    const values = value.split('-');
    const mallId = Number(values[0]);
    const boundary = values[2];
    const center = values[1];
    const adcode = values[3];
    const city = value[4];
    this.mallSelectModal = value;
    this.mallId = mallId;
    this.adcode = Number(adcode);
    this.city = city;
    this.gridData = await this.gridDataHandler();
    this.map.setZoom(this.zoom);
    this.mallChangeHandler2(center, boundary);
    if (this.mallStatus) {
      this.drawGrid(this.gridData);
    }
    const aoiData = await this.aoiDataHandler();
    this.aoiRegionHandler(aoiData);
  }

  tipsClickHandler(): void {
    const content1 = '1、点击“开始勾画”按钮，在地图上勾画一个闭合区域';
    const content2 = '2、勾画面积不能超过10 平方公里，否则将无法通过审核';
    const content3 = '3、勾画完成，按提示为勾画区域命名，然后点击“上传”';
    this.$Modal.info({
      title: this.title,
      content: '',
      width: '423px',
      okText: '知道了',
      render: (h?: any) => {
        if (!h) {
          return '';
        }
        return h('div', {}, [
          h('p', {
            style: { marginBottom: '15px', marginTop: '20px' },
            domProps: { innerHTML: content1 },
          }),
          h('p', {
            style: { marginBottom: '15px' },
            domProps: { innerHTML: content2 },
          }),
          h('p', {
            style: { marginBottom: '15px' },
            domProps: { innerHTML: content3 },
          }),
        ]);
      },
    });
  }

  returnPage(): void {
    this.$emit('addRegionChange', false);
  }

  async getAOILookOverInfo(params: any): Promise<void> {
    const data = await getAOILookOverInfo(params);
    return data;
  }
}
