
















































































































import { Vue, Component } from 'vue-property-decorator';
import viewRegion from './viewRegion/index.vue';
import addRegion from './addRegion/index.vue';
import { getContainer } from '@/components';
import { aoiRegionStatus, aoiType } from '@/config/global';

import {
  getAoiRegions,
  updateAoiRegionByIds,
  updateManualAoiName,
} from '@/services/sourceApi';
import { CreateElement } from 'vue';
import { isXss } from '@/components/map/map';
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
@Component({
  components: {
    viewRegion,
    addRegion,
  },
})
export default class System extends Vue {
  querylist = [
    {
      label: '请选择',
      value: 0,
    },
    {
      label: '区域ID',
      value: 1,
    },
    {
      label: '区域名称',
      value: 2,
    },
    {
      label: '区域类型',
      value: 3,
    },
    {
      label: '所属MALL项目',
      value: 4,
    },
    {
      label: '申请用户',
      value: 6,
    },
    {
      label: '申请用户手机号',
      value: 7,
    },
  ];
  regionLoading = false;
  searchKeyModal = false;
  searchValueModal = false;
  updateAoiNameStatus = false;
  searchVal = '';
  current = 1;
  searchKey = 0;
  regionStatus = 3;
  viewRegionShow = false;
  addRegionShow = false;
  bannerShow = true;
  regionTotal = 0;
  title = '申请客流来源区域';
  typeStatusObj: any = aoiType;
  typeTextObj: any = {
    居住地: '1',
    工作地: '2',
    过路地: '3',
  };
  regionStatusList = [
    {
      value: 3,
      label: '全部',
    },
    {
      value: 1,
      label: '已通过',
    },
    {
      value: 2,
      label: '待审核',
    },
    {
      value: -1,
      label: '已驳回',
    },
  ];

  regionColumnPage = [
    {
      title: '区域ID',
      key: 'aoi_id',
      align: 'center',
    },
    {
      title: '区域名称',
      key: 'aoi_name',
      align: 'center',
      tooltip: true,
      width: 160,
    },
    {
      title: '区域类型',
      key: 'num_type',
      align: 'center',
      width: 100,
      render: (h: CreateElement, params: any) => {
        const { num_type } = params.row;
        const text = this.typeStatusObj[num_type];
        return h('div', {
          domProps: {
            innerText: text,
          },
        });
      },
    },
    {
      title: '城市',
      key: 'city',
      align: 'center',
      tooltip: true,
    },
    {
      title: '区县',
      key: 'district',
      align: 'center',
      tooltip: true,
    },
    {
      title: '所属MALL项目',
      key: 'mall_name',
      align: 'center',
      width: 140,
      tooltip: true,
    },
    {
      title: '申请用户',
      key: 'user',
      align: 'center',
      tooltip: true,
    },
    {
      title: '申请用户手机号',
      key: 'tel',
      align: 'center',
      width: 140,
    },
    {
      title: '申请时间',
      key: 'create_time',
      align: 'center',
      width: 200,
    },
    {
      title: '区域状态',
      key: 'status',
      align: 'center',
      render: (h: CreateElement, params: any) => {
        const { status } = params.row;
        const statusObj = aoiRegionStatus;
        const text = statusObj[status];
        return h('div', {
          domProps: {
            innerText: text,
          },
        });
      },
    },
    {
      title: '操作',
      key: 'operate',
      slot: 'operate',
      align: 'center',
      width: 200,
    },
  ];
  regionData = [];
  modalText = '';
  modalStatus = false;
  deleteText = '是否要删除区域';
  rowData: any = {};
  aoiNameIpt = '';
  aoiId = '';
  containerHeight = '0';

  async mounted(): Promise<void> {
    this.containerHeight = `${getContainer(64)}px`;
    const val = 1;
    this.regionPageChange(val);
  }

  goBack(val: boolean): void {
    this.viewRegionShow = val;
  }
  reset(): void {
    // if(this/)
    this.searchKey = 0;
    this.regionStatus = 3;
    this.searchVal = '';
    this.current = 1;
    this.regionPageChange(this.current);
    // 有个接口申请初始页面数据
  }

  resetPage(): void {
    this.regionPageChange(this.current);
  }

  async searchClickHandler(): Promise<void> {
    if (!this.searchKey) {
      this.searchKeyModal = true;
      return;
    }
    if (!this.searchVal) {
      this.searchValueModal = true;
      return;
    }
    this.regionPageChange(1);
  }
  async regionPageChange(val: number): Promise<void> {
    this.regionLoading = true;
    this.searchVal = this.searchVal.trim();
    let { searchVal } = this;
    if (this.searchKey === 3) {
      searchVal = this.typeTextObj[this.searchVal] || this.searchVal;
    }
    const params = {
      page: val,
      rows: 10,
      search_type: this.searchKey,
      search_str: searchVal,
      status: this.regionStatus,
    };
    const res: any = await this.getAoiData(params);
    this.regionData = res.data;
    this.regionTotal = Number(res.total);
    this.current = val;
    this.regionLoading = false;
  }

  async getAoiData(params: any): Promise<void> {
    const data = await getAoiRegions(params);
    return data;
  }

  async updateAoiRegionByIds(params: any): Promise<void> {
    const data = await updateAoiRegionByIds(params);
    return data;
  }

  async updateManualAoiName(params: any): Promise<void> {
    const data = await updateManualAoiName(params);
    return data;
  }
  viewRegion(row: any): void {
    const { aoi_id: aoiId } = row;
    this.aoiId = aoiId;
    this.viewRegionShow = true;
    (this.$refs.viewRegion as any).pageClickHandler(aoiId);
  }

  viewRegionChange(status: boolean): void {
    this.viewRegionShow = status;
    this.regionPageChange(this.current);
  }

  addRegionChange(status: boolean): void {
    this.addRegionShow = status;
    this.bannerShow = !status;
    this.regionPageChange(this.current);
  }
  addRegion(): void {
    this.addRegionShow = true;
    this.bannerShow = false;
    // (this.$refs.addRegion as any).pageClickHandler();
  }
  btKeyUpAoiName(e: any): void {
    e.target.value = isXss(e.target.value);
    this.aoiNameIpt = e.target.value;
  }

  regionChangeHandler(params: any): void {
    const { value } = params;
    this.regionStatus = value;
    this.regionPageChange(1);
  }

  deleteAoi(row: any): void {
    this.modalText = this.deleteText;
    this.rowData = row;
    this.$Modal.confirm({
      title: this.title,
      content: this.deleteText,
      onOk: async () => {
        this.deleteClickHandler();
      },
    });
  }

  updatAoiName(row: any): void {
    // this.aoiNameIpt = '';
    this.rowData = row;
    console.log(this.rowData);
    this.updateAoiNameStatus = true;
  }

  updateAoiNameClickHandler(): void {
    this.updateAoiName();
  }

  async updateAoiName(): Promise<void> {
    if (this.aoiNameIpt === '') {
      this.$Modal.error({
        title: this.title,
        content: '区域名称不能为空',
        onOk: async () => {
          this.updateAoiNameStatus = true;
        },
      });
      return;
    }
    if (this.rowData) {
      const { aoi_id, mall_id } = this.rowData;
      const params = {
        aoi_id,
        mall_id,
        aoi_name: this.aoiNameIpt,
        status: 0,
      };
      const res: any = await this.updateManualAoiName(params);
      const text = '修改区域';
      if (res.status === 0 && res.data) {
        this.$Modal.success({
          title: text,
          content: '名称修改成功',
          onOk: async () => {
            this.regionPageChange(this.current);
          },
        });
      } else {
        this.$Modal.error({
          title: text,
          content: `名称修改失败-${res.info}`,
          // onOk: async () => {
          //   this.regionPageChange(this.current);
          // },
        });
      }
      this.rowData = {};
    }
  }

  async deleteClickHandler(): Promise<void> {
    if (this.rowData) {
      const { aoi_id, num_type } = this.rowData;
      const params = {
        aoi_id,
        num_type,
        status: 0,
      };
      const res: any = await this.updateAoiRegionByIds(params);
      const text = '删除区域';
      if (res.status === 0 && res.data) {
        setTimeout(() => {
          this.$Modal.success({
            title: text,
            content: '区域删除成功',
            onOk: async () => {
              this.regionPageChange(this.current);
            },
          });
        }, 100);
      } else {
        setTimeout(() => {
          this.$Modal.error({
            title: text,
            content: '区域删除失败',
            onOk: async () => {
              this.regionPageChange(this.current);
            },
          });
        }, 100);
      }
      this.rowData = {};
    }
  }
}
