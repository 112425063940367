



























import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
  components: {
  },
})
export default class RegionLegend extends Vue {
  iconType = 'ios-arrow-down';
  legendShow = true;
  @Prop({
    type: String,
    required: true, // 是否必填
    default: '',
  })  nonCompliance !: string;
  imgClickHandler(): void{
    const down = 'ios-arrow-down';
    const up = 'ios-arrow-up';
    this.iconType = this.iconType === down ? up : down;
    this.iconType === down ? this.legendShow = true : this.legendShow = false;
  }
}
