




































































import { Vue, Component } from 'vue-property-decorator';
import {
  initMap,
  zoomUp,
  zoomDown,
  processPolygonData,
  drawPolygon,
  overlayCenter,
  clearOverlay,
  regionPolygonStyle,
  drawMarker,
  drawLabel,
} from '@/components/map/map';
import {
  getAOILookOverInfo,
  updateAoiRegionByIds,
} from '@/services/sourceApi';
import regionLegend from '@/components/RegionLegend/index.vue';
import { aoiRegionStatus, aoiType } from '@/config/global';
import { LabelStyle } from '@/types/store';
@Component({
  components: {
    regionLegend,
  },
})
/* eslint-disable @typescript-eslint/explicit-module-boundary-types*/
/* eslint-disable @typescript-eslint/naming-convention*/
export default class ViewRegion extends Vue {
  map!: any;
  radioModal = 1;
  initRadioModal = this.radioModal;
  isModalActive = true;
  isLeftActive = false;
  title = '查看区域';
  aoiName = '';
  mallName = '';
  province = '';
  user = '';
  createTime1 = '';
  createTime2 = '';
  aoiStatus = '';
  updateDisable = true;
  status!: number;
  aoiId!: number;
  polygon!: any;
  marker!: any;
  zoom = 16;
  labelStyle: LabelStyle = {
    color: '#4583ff',
    border: 'none',
    backgroundColor: 'none',
    pointerEvents: 'none',
    transform: 'translateY(-45%) translateX(-50%)',
    zIndex: 10,
  };
  nameLabel: any;
  async mounted(): Promise<void> {
    const el = 'map-container';
    const center = '39.916527,116.397128';
    this.map = initMap(el, this.zoom, center);
  }

  async pageClickHandler(aoiId: number): Promise<void> {
    const params = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      aoi_id: aoiId,
    };
    this.aoiId = aoiId;
    const res: any = await this.getAOILookOverInfo(params);
    if (res.status === 0 && res.data) {
      const data = res.data[0];
      const {
        aoi_name: aoiName,
        mall_name: mallName,
        province,
        user,
        create_time: createTime,
        status,
        num_type: numType,
        boundary,
        centre_ll: center,
      } = data;
      const time = createTime.split(' ');
      const createTime1 = time[0];
      const createTime2 = time[1];
      this.radioModal = numType;
      this.initRadioModal = numType;
      this.aoiName = aoiName;
      this.mallName = mallName;
      this.province = province;
      this.user = user;
      this.createTime1 = createTime1;
      this.createTime2 = createTime2;
      this.aoiStatus = aoiRegionStatus[status];
      this.status = status;
      const centers = overlayCenter(center);
      this.map.setCenter(centers);
      const path = processPolygonData(boundary);
      this.polygon = drawPolygon(this.map, path);
      regionPolygonStyle(status, this.polygon);
      // 名称覆盖物
      const nameContent = `<div style="width: 100%;height:100%;text-align:center;font-size: 14px;margin-top:54px;">${aoiName}</div>`;
      this.nameLabel = drawLabel(
        this.map,
        centers,
        nameContent,
        0,
        aoiId,
        this.labelStyle,
        true,
      );
      this.marker = drawMarker(this.map, centers);
    } else {
      this.$Modal.error({
        title: this.title,
        content: '查询异常',
      });
      this.updateDisable = true;
    }
  }

  zoomUp(): void {
    zoomUp(this.map);
  }

  zoomDown(): void {
    zoomDown(this.map);
  }

  returnPage(): void {
    this.$emit('viewRegionChange', false);
    clearOverlay(this.polygon);
    clearOverlay(this.marker);
    clearOverlay(this.nameLabel);
    this.isLeftActive = false;
  }

  regionCloseHandler(): void {
    this.isLeftActive = this.isLeftActive !== true;
  }

  radioChangeHandler(status: number): void {
    this.radioModal = status;
    if (this.radioModal !== this.initRadioModal) {
      this.updateDisable = false;
    } else {
      this.updateDisable = true;
    }
  }

  updateRegionHandler(): void {
    // 防止未修改进行提交
    if (this.initRadioModal === this.radioModal) {
      this.$Modal.info({
        title: this.title,
        content: '未修改不允许提交',
      });
      return;
    }
    const aoiTypeText = aoiType[this.radioModal];
    this.$Modal.confirm({
      title: this.title,
      content: `确认修改区域人数类型为(${aoiTypeText})`,
      onOk: async () => {
        this.updateAoiRegionHandler();
      },
    });
  }
  async updateAoiRegionHandler(): Promise<void> {
    const params = {
      aoi_id: this.aoiId,
      num_type: this.radioModal,
      status: this.status,
    };
    const res: any = await this.updateAoiRegionByIds(params);
    if (res.status === 0 && res.data) {
      setTimeout(() => {
        this.$Modal.success({
          title: this.title,
          content: '区域修改成功',
          onOk: async () => {
            this.initRadioModal = this.radioModal;
            this.updateDisable = true;
          },
        });
      }, 100);
    } else {
      setTimeout(() => {
        this.$Modal.error({
          title: this.title,
          content: '区域修改失败',
          onOk: async () => {
            // console.log(2);
          },
        });
      }, 100);
    }
  }

  async getAOILookOverInfo(params: any): Promise<void> {
    const data = await getAOILookOverInfo(params);
    return data;
  }

  async updateAoiRegionByIds(params: any): Promise<void> {
    const data = await updateAoiRegionByIds(params);
    return data;
  }
}
